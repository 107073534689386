import React from 'react';
import { useParams } from 'react-router-dom';
import useFirestoreUser from '../../hooks/useFirestoreUser';
import template from 'lodash.template';

const CRISP_CHAT_URL = 'https://app.crisp.chat/website/${site_id}/inbox/${session_id}/';
const COACH_SITE_ID = '162da537-8f73-4d96-9d27-492cabc0ccab';

export default () => {
  const { uid } = useParams();
  const [user, error, isLoading] = useFirestoreUser(uid);

  if (isLoading) {
    return <div>Loading, please wait...</div>;
  }

  if(error) {
    return <div>unexpected error: {error}</div>;
  }

  const crispSessionId = user.crisp_session_id;

  if (!crispSessionId) {
    return <div>User hasn't a Crisp session to open. Probably because never entered the couch chat or is not a "couchable" user.</div>;
  }
  
  window.location.href = template(CRISP_CHAT_URL)({
    site_id: COACH_SITE_ID,
    session_id: crispSessionId,
  });

  return <div>redirecting to Crisp...</div>;
};
