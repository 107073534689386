import React, { useState, useEffect } from 'react';
import { functions } from 'firebase';
import { Spin, Space, Layout, PageHeader } from 'antd';
import moment from 'moment';
import GenericFooter from './../../components/GenericFooter';
import GenericHeader from './../../components/GenericHeader';
import { setLoading } from '../../redux/actions/gympass';
import './index.scss';

const { Content } = Layout;

const parseInput = text => text.split(/[\s,;\t\n]+/).map(e => e.replace(/[^A-Za-z0-9\s!?]/g,'')).filter(r => r);

const InspectEmails = () => {
  const [uids, setUIDs] = useState([]);
  const [loading, setLoading] = useState();
  const [inputFocus, setInputFocus] = useState(true);
  const [emails, setEmails] = useState([]);
  // console.log(uids);
  const inputHandler = (e) => {
    // console.log(e.currentTarget.value);
    if (e.currentTarget.value) {
      setUIDs(parseInput(e.currentTarget.value));
    }
  }
  useEffect(() => {
    if (uids.length > 0) {
      setLoading(true);
      functions().httpsCallable('dengerouslyGetEmailUsers')(uids)
        .then(res => {
          console.log(res);
          setEmails(res.data);
        })
        .finally(() => setLoading(false));
    }
  },[uids])
  return (
    <Layout className="layout">
      <GenericHeader />
      <Content style={{ padding: '0 25px', margin: '25px' }}>
        <PageHeader
          title="Email recovery wizard 🧙"
        />
        <div className="textareas-container">
          <div className="row">
            <div className="col">
                <h2>Paste UIDs here 👇</h2>
                <small>(separated by space, comma, semicolon, tab or newline)</small>
            </div>
            <div style={{ minWidth: '70px' }} />
            <div className="col">
              {emails.filter(r => r).length !== 0 && <h2>What you're looking for 👇</h2>}
              {emails.filter(r => r).length === 0 && emails.length !== 0 && <h2>You didn't say the magic word</h2>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea onChange={inputHandler} onFocus={() => setInputFocus(true)} onBlur={() => setInputFocus(false)} />
            </div>
            <div style={{ minWidth: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              { loading && (
                <Space size="middle">
                  <Spin size="large" />
                </Space>
              )}
            </div>
            <div className="col">
              <textarea defaultValue={emails.map(r => r === false ? ' ' : r).join('\n')}/>
            </div>
          </div>
        </div>
      </Content>
      <GenericFooter />
    </Layout>
  )
};

export default InspectEmails;
