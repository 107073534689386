import React from 'react';
import { useTranslation } from 'react-i18next';
import googlePlayLogo_en from './../../assets/submitWithGooglePlay_en.png';
import appleStoreLogo_en from './../../assets/submitWithAppleStore_en.png';
import googlePlayLogo_es from './../../assets/submitWithGooglePlay_es.png';
import appleStoreLogo_es from './../../assets/submitWithAppleStore_es.png';
import { APPLE_STORE_URL, GOOGLE_PLAY_URL } from '../../config/constants';

const getButtonByLAnguage = lang => {
  switch(lang) {
    case 'en':
      default:
      return { apple: appleStoreLogo_en, google: googlePlayLogo_en };
    case 'es':
      return { apple: appleStoreLogo_es, google: googlePlayLogo_es };
  }
}
const OperatingSystem = /android/i.test(navigator.userAgent)
  ? 'android'
  : /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    ? 'ios'
    : 'unknown';

export default () => {
  const { i18n } = useTranslation();
  return (
    <div className="submit-row">
      {(OperatingSystem === 'android' || OperatingSystem === 'unknown') &&
        <a className="link-submit-img" href={GOOGLE_PLAY_URL}>
          <img type="submit" src={getButtonByLAnguage(i18n.language).google} className="logo" alt="Google Play" />
        </a>
      }
      {(OperatingSystem === 'ios' || OperatingSystem === 'unknown') &&
        <a className="link-submit-img" href={APPLE_STORE_URL}>
          <img type="submit" src={getButtonByLAnguage(i18n.language).apple} className="logo" alt="App Store"/>
        </a>
      }
    </div>
  )
}