import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import "./BasicUserHeaderMeta.scss";

const { Meta } = Card;

export default () => {
  const { t } = useTranslation(['gympass_registration']);
  return (
    <Meta
      className="registration-card-meta"
      title={t('basic_registration_banner_text')}
      description={<ul className="features-container">
        <li className="feature-item app"><div className="icon" />{t('basic_promo_1')}</li>
        <li className="feature-item h2h"><div className="icon" />{t('basic_promo_2')}</li>
        <li className="feature-item dashboard"><div className="icon" />{t('basic_promo_3')}</li>
        <li className="feature-item science"><div className="icon" />{t('basic_promo_4')}</li>
      </ul>}
    />
  )
}
