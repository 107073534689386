export default {
  type: process.env.NODE_ENV,
  domain: `https://${process.env.NODE_ENV === 'development' ? 'app-dev.mindcotine.com' : 'us-central1-mindcotine-v4-production.cloudfunctions.net'}`,
  apiPath: '/api',
  gympassIntegrationKey: [
    'G', 'c', '9', 'u', 'l', 'a',
    'B', 'h', 'b', 'I', 'g', 'q',
    'c', '8', 'c', 'y', 'S', 'H',
    '7', 'R', 'G', 'r', 'A', 'i',
    'E', '9', 'G', 'C', 'H', 'i',
    'u', 'L'
  ].join(''),
  //'Gc9ulaBhbIgqc8cySH7RGrAiE9GCHiuL',
}