import { combineReducers } from 'redux';
export const initialState = {
  isLoading: false,
  busyMessage: '',
  email: '',
  name: '',
  lastname: '',
  country: '',
  status: '',
  alreadyRegistered: false,
  uid: '',
  isPremium: '',
  hasShippingInfo: '',
};

export const reducer = (state = initialState, action) => {
  const reducer = {};
  reducer.SET_LOADING = () => {
    const newVal = state.isLoading + action.payload;
    return {
      ...state,
      // instead of boolean we store integers so we can pile up loadings
      isLoading: newVal >= 0 ? newVal : 0,
    };
  };
  reducer.SET_BUSY_MESSAGE = () => ({
    ...state,
    busyMessage: action.payload,
  });
  reducer.SET_PARAMS = () => {
    const params = {
      email: action.payload.email || state.email,
      name: action.payload.name || state.name,
      lastname: action.payload.lastname || state.lastname,
      country: action.payload.country || state.country,
      status: action.payload.status || state.status,
    }
    return {
      ...state,
      ...params,
    }
  };
  reducer.SET_ALREADY_REGISTERED = () => ({
    ...state,
    alreadyRegistered: action.payload,
  });
  reducer.SET_EMAIL = () => ({
    ...state,
    email: action.payload,
  });
  reducer.SET_NAME = () => ({
    ...state,
    name: action.payload,
  });
  reducer.SET_LASTNAME = () => ({
    ...state,
    lastname: action.payload,
  });
  reducer.SET_COUNTRY = () => ({
    ...state,
    country: action.payload,
  });
  reducer.SET_STATUS = () => ({
    ...state,
    status: action.payload,
  });
  reducer.SET_UID = () => ({
    ...state,
    uid: action.payload,
  });
  reducer.SET_IS_PREMIUM = () => ({
    ...state,
    isPremium: action.payload,
  });
  reducer.SET_HAS_SHIPPING_INFO = () => ({
    ...state,
    hasShippingInfo: action.payload,
  });
  reducer.DEFAULT = () => state;
  return ((reducer.hasOwnProperty(action.type) &&
    reducer[action.type]) ||
    reducer.DEFAULT)();
}

export default combineReducers({
  gympass: reducer,
});