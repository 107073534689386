import { useState, useEffect } from 'react';
import { auth } from 'firebase'
const useAlreadyUserValidator = email => {
  const [initializing, setInitializing] = useState(true)
  const [isRegisteredUser, setIsRegisteredUser] = useState(false)
  useEffect(() => {
    if(email) {
      setInitializing(true)
      auth().fetchSignInMethodsForEmail(email)
        .then((methods) => {
          const isRegistered = methods.length !== 0
          setIsRegisteredUser(isRegistered)
        })
        .catch(() => {
          // err.code === 'auth/invalid-email'
          setIsRegisteredUser(false)
        })
        .finally(() => setInitializing(false))
    }
  }, [email])
  return [isRegisteredUser, initializing]
}

export default useAlreadyUserValidator;