/* global gtag */
import React from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import LanguagePoll from './LanguagePoll';
import "./PremiumRegistrationForm.scss";
import LegalMessage from './LegalMessage';

export default ({ form, initialValues, onFinish, setStore }) => {
  const { t } = useTranslation(['gympass_registration']);
  const chackPasswordRepeat = (_, value) => new Promise((resolve, reject) => {
    if (value !== form.getFieldValue('password')) {
      reject(t('form_error_password_match'));
    } else {
      resolve();
    }
  });
  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout="vertical"
    >
      <div className="registration-footer">{t('premium_form_subtitle')}</div>
      <br />
      <h2>{t('premium_form_step1')}</h2>
      <Row gutter={16} style={{ visibility: 'hidden', height: 1 }}>
        <Col span={12}>
          <Form.Item name="firstname">
            <Input readOnly="readonly" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="lastname">
            <Input readOnly="readonly" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="usercountry">
            <Input readOnly="readonly" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="userstatus">
            <Input readOnly="readonly" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="gpw_id">
            <Input readOnly="readonly" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t('premium_form_email_label')}
        name="email"
        rules={[
          {
            required: true,
            message: t('form_error_required_email'),
          },
          {
            type: 'email',
            message: t('form_error_invalid_email'),
          }
        ]}
      >
        <Input readOnly="readonly" />
      </Form.Item>
      <Form.Item
        label={t('premium_form_password_label')}
        validateTrigger={['onBlur']}
        rules={[
          {
            required: true,
            message: t('form_error_required_password'),
          },
          {
            min: 6,
            message: t('form_error_length_password'),
          }
        ]}
        name="password"
      >
        <Input placeholder={t('premium_form_password_placeholder')} type="password" name="password" autoComplete="new-password" />
      </Form.Item>
      <Form.Item
        label={t('premium_form_confirm_password_label')}
        validateTrigger={['onBlur']}
        rules={[
          {
            validator: chackPasswordRepeat
          }
        ]}
        name="repeat-password"
      >
        <Input placeholder={t('premium_form_password_placeholder')} type="password" name="verification" autoComplete="off" />
      </Form.Item>
      <LanguagePoll />
      <br />
      <Form.Item>
        <Button type="primary" htmlType="submit" className="MindCotine_btn">
          {t('premium_form_next')}
        </Button>
        <LegalMessage />
      </Form.Item>
    {/* <Form.Item>
      <div className="submit-row">
        {(OperatingSystem === 'android' || OperatingSystem === 'unknown') &&
          <a href="#submit_android" className="link-submit-img" onClick={() => {
            setStore('android');
            form.submit();
            gtag('event', 'cta gympass_form_android', {
              'category': 'CTA',
              'action': 'cta clicked',
              'label': 'cta_gympass_android'
            });
          }}>
            <img type="submit" src={googlePlayLogo} className="logo" alt="Google Play" />
          </a>
        }
        {(OperatingSystem === 'ios' || OperatingSystem === 'unknown') &&
          <a href="#submit_ios" className="link-submit-img" onClick={() => {
            setStore('ios');
            form.submit();
            gtag('event', 'cta gympass_form_ios', {
              'category': 'CTA',
              'action': 'cta clicked',
              'label': 'cta_gympass_ios'
            });
          }}>
            <img type="submit" src={appleStoreLogo} className="logo" alt="App Store" />
          </a>
        }
        </div>
      </Form.Item> */}
    </Form>
  )
}