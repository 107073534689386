import { useState, useEffect } from 'react';
import { functions } from 'firebase';

const loadGympassUserData = email => new Promise(async (resolve, reject) => {
  try {
    const response = await functions()
      .httpsCallable('getGympassUserDataByEmail')(email);

    resolve(response);
  } catch (err) {
    reject(err);
  }
});
const useGympassUserData = email => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setError('');
    if (email) {
      loadGympassUserData(email)
        .then(r => {
          setData(r);
          setError('');
        })
        .catch(e => setError(e.message))
        .finally(() => setLoading(false));
    }
  }, [email]);
  return [data, error, isLoading];
}

export default useGympassUserData;
