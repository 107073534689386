// global window
const crispExists = () => window.hasOwnProperty('$crisp');
const bootUpCrisp = (crispId = 'f6319faa-7830-4a7d-922d-ab4097ca96ad') => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = crispId;
  (function () {
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
    // console.log('CRISP INJECTED');
  })();
};
const push = props => window.$crisp.push(props);
const setEmail = email => window.$crisp.push(["set", "user:email", email]);
const setTheme = (themeName = "teal") => window.$crisp.push(["config", "color:theme", [themeName]]);
const openChat = () => window.$crisp.push(['do', 'chat:open']);
const hideOperatorCount = () => window.$crisp.push(["config", "show:operator:count", [false]]);

const useCrisp = () => {
  if (!crispExists()) {
    bootUpCrisp();
  }
  return {
    push,
    setEmail,
    setTheme,
    openChat,
    hideOperatorCount,
  }
};

export default useCrisp;
