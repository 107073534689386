/* global gtag */
import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Layout,
  Modal,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import { isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as actions from '../../redux/actions/gympass'
import './index.scss';
import AES256 from 'aes-everywhere';
import useCrisp from '../../hooks/useCrisp';
import useAlreadyUserValidator from '../../hooks/useAlreadyUserValidator';
import config from './../../config/env';
import useGympassUserData from '../../hooks/useGympassUserData';
import BasicUserMeta from './BasicUserHeaderMeta';
import PremiumUserMeta from './PremiumUserHeaderMeta';
import BasicUserRegisteredInfo from './BasicUserRegisteredInfo';
import PremiumUserRegisteredInfo from './PremiumUserRegisteredInfo';
import StoreButtons from './StoreButtons';
import RegistrationForm from './RegistrationForm';
import PremiumRegistrationForm from './PremiumRegistrationForm';
import { SHIPPABLE_COUNTRIES } from '../../config/constants';

// ==========================
// demo url
// http://localhost:3000/integrations/gympasssignup?email=U2FsdGVkX19AIjy%2B9oASZoMkEhK3Ti3GCon56eb%2BS7oyfzEKSYf3nCK3KVvmMlZB&firstname=U2FsdGVkX18s%2FSmkSDrO2%2BcBehCckVFZ07s4L6JRNdc%3D&gpw_id=U2FsdGVkX1%2B9YeLgC4jQA%2BfYON0ftrDzBi1%2FG8dT6hXTgLR7buCAmwsJZtH66Rjfdktz%2BB%2BJKO0ZacxQenO6OQ%3D%3D&lastname=U2FsdGVkX18PjeOrfCUs2%2BmIXF0OIGQqEKLEBBCBigw%3D&usercountry=U2FsdGVkX1%2FKEZZF4RMDoVFkxs2Al73fwWPF9oV7eQA%3D&userstatus=U2FsdGVkX19t8JMq%2FpRw6%2FEyPosqz6gUTv5kCD3KK%2Bc%3D
// encode utility
// console.log(encodeURIComponent(AES256.encrypt(
//   'ar',
//   config.gympassIntegrationKey
// )));
// ==========================
const { Content } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
// custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => new URLSearchParams(useLocation().search);

const isShippableCountry = countryCode => SHIPPABLE_COUNTRIES.includes(countryCode.toLowerCase());
const registerUser = async formData => {
  let result;
  try {
    result = await firebase.auth().createUserWithEmailAndPassword(formData.email, formData.password);
  } catch(error) {
    alert(error.message);
    return false;
  }
  
  try {
    const form = {
      uid: result.user.uid,
      name: formData.firstname,
      email: formData.email,
      country: formData.usercountry,
      isPremium: Number(formData.userstatus) > 1,
      source: 'Gympass',
      // just for registration log, not preserved into Firestore user node.
      userstatus: formData.userstatus,
      gpw_id: formData.gpw_id,
      lang: (navigator.language || navigator.userLanguage).substr(0,2),
    }
    await firebase.functions().httpsCallable('registerUser')(form);
  } catch (error) {
    console.error(error);
    // silenty fail for user
    return false;
  }
  return result;
}
const APPLE_STORE_URL = 'https://apps.apple.com/us/app/mindcotine/id1506021271?ls=1';
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.habitfly.mindcotine';
const getStoreUrlByName = storeName => {
  return storeName === 'ios' ? APPLE_STORE_URL : GOOGLE_PLAY_URL
}

const GympassRegistration = ({
  actions,
  isLoading,
  isPremium: isGympassPremium,
  email: decryptedEmail,
  alreadyRegistered,
  country,
}) => {
  const { t } = useTranslation(['gympass_registration']);
  const [form] = Form.useForm();
  const [storeName, setStore] = useState('android');
  const [busyMessage, setBusyMessage] = useState(t('busy_message_entering'));
  const history = useHistory();

  const { setEmail: setCrispEmail, setTheme, openChat } = useCrisp();
  const [registeredState, checkingUserStatus] = useAlreadyUserValidator(decryptedEmail);
  const [userData, userError, isLoadingUserData] = useGympassUserData(decryptedEmail)

  const query = useQuery();
  // AES256 + base64 url param to plain text.
  const getDecryptedFromUrl = paramName => {
    let result;
    try {
      result = AES256.decrypt(
        query.get(paramName),
        config.gympassIntegrationKey
      );
    } catch (error) {
      console.error(error);
    }
    return result;
  }
 
  useEffect(() => {
    // config Crisp
    setTheme('teal');

    actions.setParams({
      email: getDecryptedFromUrl('email'),
      name: getDecryptedFromUrl('firstname'),
      lastname: getDecryptedFromUrl('lastname'),
      country: getDecryptedFromUrl('usercountry'),
      status: getDecryptedFromUrl('userstatus'),
    });
    const userstatus = getDecryptedFromUrl('userstatus');
    actions.setIsPremium(Number(userstatus) === 2);
  },[]);

  useEffect(() => {
    actions.setAlreadyRegistered(registeredState)
  }, [registeredState])

  useEffect(() => {
    if (decryptedEmail) {
      setCrispEmail(decryptedEmail);
    }
  }, [decryptedEmail]);

  useEffect(() => {
    const updateUserState = async () => {
      const status = getDecryptedFromUrl('userstatus');
      const usercountry = getDecryptedFromUrl('usercountry');
      const gpw_id = getDecryptedFromUrl('gpw_id');
      const result = await firebase.functions().httpsCallable('updateReturningGympassUser')({
        email: decryptedEmail,
        status,
        usercountry,
        gpw_id,
      });
      actions.setUid(result?.data);
    };
    if (alreadyRegistered) {
      updateUserState();
    }
  }, [alreadyRegistered]);

  const submit = async values => {
    actions.setLoading(1);
    let success = false;
    // wait for user registration, on fail alert error message
    try {
      const regResult = await registerUser(values);
      if (!regResult) {
        alert(t('generic_something_wrong_message'));
      } else {
        actions.setUid(regResult.user.uid);
        success = isObject(regResult)
      }
    } catch (error) {
      alert(error.message);
    }
    
    // if user was registered, redirect to next page
    // basic: to app store
    // premium: to shipping
    if (success) {
      if (isGympassPremium && isShippableCountry(country)) {
        setBusyMessage(t('busy_message_redirecting'));
        actions.setLoading(-1);
        history.push('/integrations/gympasssuccess');
      } else {
        setBusyMessage(t('busy_message_store_redirecting'));
        window.location.href = getStoreUrlByName(storeName);
      }
    } else {
      actions.setLoading(-1);
    }
  };
  const informationBlockByUserStatus = isGympassPremium && isShippableCountry(country)
    ? <PremiumUserRegisteredInfo
        email={decryptedEmail}
        hasShippingData={userData?.data?.hasShippingData}
        navigateToShippingForm={() => history.push('/integrations/gympasssuccess')}
      />
    : <BasicUserRegisteredInfo
        email={decryptedEmail}
      />
  const loadingData = isLoadingUserData || checkingUserStatus;
  const cardMetaByUserStatus = isGympassPremium && isShippableCountry(country) ? <PremiumUserMeta />: <BasicUserMeta />;
  const registrationFormByUserStatus = isGympassPremium  && isShippableCountry(country)
  ? <PremiumRegistrationForm
    form={form}
    initialValues={{
      firstname: getDecryptedFromUrl('firstname'),
      lastname: getDecryptedFromUrl('lastname'),
      userstatus: getDecryptedFromUrl('userstatus'),
      usercountry: getDecryptedFromUrl('usercountry'),
      gpw_id: getDecryptedFromUrl('gpw_id'),
      email: decryptedEmail,
    }}
    onFinish={submit}
    setStore={setStore}
  />
  :
  <RegistrationForm
    form={form}
    initialValues={{
      firstname: getDecryptedFromUrl('firstname'),
      lastname: getDecryptedFromUrl('lastname'),
      userstatus: getDecryptedFromUrl('userstatus'),
      usercountry: getDecryptedFromUrl('usercountry'),
      gpw_id: getDecryptedFromUrl('gpw_id'),
      email: decryptedEmail,
    }}
    onFinish={submit}
    setStore={setStore}
  />;
  return (
    <Layout className="gympass-registration form-layout">
      <Content>
        <div className="header" />
        <Card bordered={false} className="registration-card"
          cover={<img alt="MindCotine" className="cover-logo" src="/logo216.png" />}
        >
          {cardMetaByUserStatus}
          <h2>{t('title')}</h2>
          {loadingData && (
            <>
              <span>{t('busy_message_verifying_email')}</span> {antIcon}
            </>)}
          {!loadingData && alreadyRegistered &&
            /* USER REGISTERED, SHOWS INSTRUCTIONS */
            <React.Fragment>
              { informationBlockByUserStatus }
              <br/><br/>
              { ((isGympassPremium && userData?.data?.hasShippingData) || !isGympassPremium) && <StoreButtons />}
              <br /><br />
              <div className="registration-footer">{t('support_label')}&nbsp;<a onClick={openChat}>{t('support_CTA')}</a></div>
            </React.Fragment>

          }
          {!loadingData && !alreadyRegistered &&
            /* USER NOT REGISTERED, SHOWS FORM */
            <React.Fragment>
              { registrationFormByUserStatus }
              <div className="registration-footer">{t('support_label')}&nbsp;<a onClick={openChat}>{t('support_CTA')}</a></div>
            </React.Fragment>
          }
        </Card>
      </Content>
        <Modal
          centered
          visible={isLoading}
          footer={null}
          closable={false}
        >
          <Spin indicator={antIcon} /> {busyMessage}...
        </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({ ...state.gympass });
const mapDispatchToProp = dispatch => ({ actions: bindActionCreators(actions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProp )(GympassRegistration);
