import React, { useState } from 'react';
import { Layout, Card, Col, Row, Input, Divider, Tag } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import GenericFooter from './../../components/GenericFooter';
import GenericHeader from './../../components/GenericHeader';
import './index.scss';

const { Content } = Layout;
const NewTagStyle = {
  borderRadius: 20,
  height: 16,
  fontSize: 10,
  lineHeight: '15px',
  marginLeft: 5,
};
const Dashboard = () => {
  const [timelineUserUID, setTimelineUserUID] = useState();
  return (
    <Layout className="layout">
      <GenericHeader />
      <Content style={{ padding: '0 50px', margin: '50px' }}>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Tools" bordered={false}>
              <Link to="/admin/inspectEmails">INSPECT EMAILS</Link><Tag color="green" style={NewTagStyle}>NEW</Tag>
              <br />
              <Link to="/admin/sms">INTERACT WITH SMS</Link>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Data Studio Reporting" bordered={false} className="links-card">
              <a href="https://datastudio.google.com/reporting/7ad8decf-6f62-4570-8727-b7786c1efa06" target="_blank">
                  KPIs <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/d80240e3-594b-43b8-826d-4a8ba4d81c35/page/RRqXB" target="_blank">
                Cohorts by weeks <ArrowUpOutlined rotate={45} />
              </a>
              
              <Divider plain>Customers</Divider>
              <a href="https://datastudio.google.com/reporting/9c512718-8f94-49c3-94c2-1bff6f6a7e46" target="_blank">
                  Bancor <ArrowUpOutlined rotate={45} /><Tag color="green" style={NewTagStyle}>NEW</Tag>
              </a>
              <a href="https://datastudio.google.com/reporting/4d38ab7f-b891-464f-a942-bb1c5ff0103e" target="_blank">
                  Cba. Gob. <ArrowUpOutlined rotate={45} /><Tag color="default" style={NewTagStyle}>UPDATE</Tag>
              </a>
              <a href="https://datastudio.google.com/u/0/reporting/ef4ec78f-0510-43dd-a6c7-d096b5041852/page/bzuNC" target="_blank">
                  Globant <ArrowUpOutlined rotate={45} />
              </a>
              <Divider plain>User details</Divider>
              <a href="https://datastudio.google.com/reporting/85062002-4991-4afc-a41f-6223e66b726e/page/StGTC" target="_blank">
                User status by customer <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/6f6b542b-1e39-490b-a9d0-dbb8af4b6506" target="_blank">
                User Engagement <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/6daa8aa4-0652-47b8-9ec8-b222cf4683f8" target="_blank">
                User Stickiness <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/1jERZbjnAM9j3zBKHm_XjloVlT-_qUen0" target="_blank">
                Users Overview <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/embed/reporting/1MT-RhDGpAqwwcI4ta7CiP1SaM7K51JQ3/page/QzwTB" target="_blank">
                Active Users <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/e3b2ab56-3c78-46e2-a780-aecd60f99491/page/ib83B" target="_blank">
                  Users to follow-up <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/12hnoi7wUVYnGMvdjIO7uFa6pJIttND5Q/page/4jTVB" target="_blank">
                Onboarding Answers <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/16euNEXB2XdjFPCuXUnvsdsJaoGqCslMK" target="_blank">
                Users Around QD <ArrowUpOutlined rotate={45} />
              </a>
              <a href="https://datastudio.google.com/reporting/b09b3e22-9459-492c-a1fc-2a87516f0201" target="_blank">
                Questionnaire answers <ArrowUpOutlined rotate={45} />
              </a>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="User timeline" bordered={false}>
              <Input type="text" placeholder="paste user uid" onChange={e => setTimelineUserUID(e.currentTarget.value)} />
              <Link to={`/admin/users/${timelineUserUID}/timeline`}>{
                timelineUserUID && 'See timeline'
              }</Link>
            </Card>
          </Col>
        </Row>
      </Content>
      <GenericFooter />
    </Layout>
  );
}

export default Dashboard;
