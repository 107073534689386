import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({ email }) => {
  const { t } = useTranslation(['gympass_registration']);
  return (
    <React.Fragment>
      <div className="registration-footer">{t('already_registered_text')}</div>
      <h2>{t('basic_registered_instruction')}</h2>
      <br /><br />
      <h2><code>{email}</code></h2>
      <br /><br />
      <div className="registration-footer">{t('basic_registered_footer')}</div>
    </React.Fragment>
  )
};
