import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './translations/en.json';
import es from './translations/es.json'

const validLanguages = ['en', 'es'];
const getLanguage = lang => {
  return validLanguages.includes(lang) ? lang : 'en';
};
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,
  es
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage((navigator.language || navigator.userLanguage).substr(0,2)),

    keySeparator: true, // we do use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;