import { useState, useEffect } from 'react';
import { firestore } from 'firebase';

const loadUserByUID = _uid => new Promise(async (resolve, reject) => {
  try {
    const userSnapshot = await firestore()
      .collection('users')
      .doc(_uid)
      .get();

    if(userSnapshot.exists) {
      resolve(userSnapshot.data());
    } else {
      reject(new Error('user not found'));
    }
  } catch (err) {
    reject(err);
  }
});
const useFirestoreUser = uid => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setError('');
    if (uid) {
      loadUserByUID(uid)
        .then(r => {
          setUser(r);
          setError('');
        })
        .catch(e => setError(e.message))
        .finally(() => setLoading(false));
    }
  }, [uid]);
  return [user, error, isLoading];
}

export default useFirestoreUser;
