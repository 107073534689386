import { useState, useEffect } from 'react';
import { auth } from 'firebase';
const ADMINS_EMAILS = [
  'emilio@mindcotine.com',
  'marcos@mindcotine.com',
  'nicolas@mindcotine.com',
  'cristian@mindcotine.com',
  'carolina@mindcotine.com',
  'joaquin@mindcotine.com',
];
const useAuth = () => {
  const [error, setError] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [initializing, setInitializing] = useState(true);
  const [authed, setAuthed] = useState(auth().currentUser);
  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(user => {
      setAuthed(user);
      setIsAdmin(ADMINS_EMAILS.includes(user?.email));
      setInitializing(false);
    })
    return () => unsubscribe();
  }, [])
  const isAuthenticated = () => {
    var user = auth().currentUser;
    if (user) {
      return true;
    } else {
      return false;
    }
  }  
  const signInWithEmailAndPassword = async (user, pass) => {
    try {
      await auth().signInWithEmailAndPassword(user, pass);
      setAuthed(auth().currentUser);
    } catch(err) {
      console.error(err);
      setError(err.message);
    }
  }
  const signInWithGoogle = async () => {
    // Code from https://firebase.google.com/docs/auth/web/google-signin
    const provider = new auth.GoogleAuthProvider();
    auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        // var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        setAuthed(user);
        // ...
      }).catch((error) => {
        console.error(error);
        setError(error.message);
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });

  }
  const signOut = () => auth().signOut();

  return {
    error,
    authed,
    isAdmin,
    initializing,
    isAuthenticated,
    signInWithEmailAndPassword,
    signInWithGoogle,
    signOut,
  }
}

export default useAuth;