import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Typography, Tag } from 'antd';
import {
  ProfileOutlined,
  UserOutlined,
  BookOutlined,
  MehOutlined,
  QuestionCircleOutlined,
  CommentOutlined,
  MedicineBoxOutlined,
  EditOutlined,
  VideoCameraOutlined,
  AudioOutlined,
  CodepenOutlined,
  NodeIndexOutlined,
  StarOutlined,
  CalendarOutlined,
  ToTopOutlined,
} from '@ant-design/icons';
// import useFirestoreUser from '../../hooks/useFirestoreUser';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment';

import useUserLogs from '../../hooks/useUserLogs';
import GenericFooter from './../../components/GenericFooter';
import GenericHeader from './../../components/GenericHeader';
import './index.scss';

const { Content } = Layout;
const { Title } = Typography;
const getIconByLogType = (logType, logSubType) => {
  let Icon = ''
  switch(logType) {
    case 'activity':
      Icon = logSubType === 'chat-message'
        ? CommentOutlined
        : logSubType === 'lifesaver'
        ? MedicineBoxOutlined
        : logSubType === 'reflection'
        ? EditOutlined
        : logSubType === '2d-video'
        ? VideoCameraOutlined
        : logSubType === 'vr-met'
        ? CodepenOutlined
        : logSubType === 'audio'
        ? AudioOutlined
        : ProfileOutlined;
      break;
    case 'user':
      Icon = logSubType === 'state'
        ? NodeIndexOutlined
        : logSubType === 'badges'
        ? StarOutlined
        : logSubType === 'quit-day'
        ? CalendarOutlined
        : logSubType === 'registration'
        ? ToTopOutlined
        : UserOutlined;
      break;
    case 'journal':
      Icon = BookOutlined;
      break;
    case 'answer':
      Icon = logSubType === 'emotion'
        ? MehOutlined
        : logSubType === 'onboarding'
        ? ProfileOutlined
        : EditOutlined;
      break;
    default:
      Icon = QuestionCircleOutlined;

  }
  return <Icon style={{ width: 40, height: 40 }} />;
}
const UserTimeline = () => {
  const { uid } = useParams();
  // const [user, userError, userIsLoading] = useFirestoreUser(uid);
  const [userLogs, logError, logIsLoading] = useUserLogs(uid);
  const lastLog = userLogs && userLogs[userLogs.length - 1];
  const latestSnapshot = lastLog && JSON.parse(lastLog.raw_data);
  console.log((userLogs || [])[0]);
  return (
    <Layout className="layout">
      <GenericHeader />
      <Content style={{ padding: '0 50px', margin: '50px' }}>
        {logIsLoading && <span>loading</span>}
        {latestSnapshot && (
          <Title level={2}>{latestSnapshot.user_snapshot.display_name}</Title>
        )}
        {!logIsLoading && userLogs &&
          <VerticalTimeline>
            {userLogs.map(log => {
              return (
                <VerticalTimelineElement
                  key={log.created_at.value}
                  className={`vertical-timeline-element element--${log.type}-${log.subtype}`}
                  // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                  date={moment(log.created_at.value).format('MMM DD')}
                  // iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  icon={getIconByLogType(log.type, log.subtype)}
                >
                  <div className="tag-container">
                    <Tag>{log.subtype}</Tag>
                  </div>
                  <h3 className="vertical-timeline-element-title">{log.type}</h3>
                  {/* <h4 className="vertical-timeline-element-subtitle">{log.subtype}</h4> */}
                  <p>
                    {log.label} - {log.value}
                  </p>
                </VerticalTimelineElement>
              )
            })}
          </VerticalTimeline>
        }
      </Content>
      <GenericFooter />
    </Layout >
  );
}

export default UserTimeline;
