import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from '../../redux/actions/gympass'
import {
  Form,
  Card,
  Layout,
  Modal,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AES256 from 'aes-everywhere';
import { firestore } from 'firebase';
import './index.scss';
import useCrisp from '../../hooks/useCrisp';
import config from './../../config/env';
import PremiumUserMeta from './PremiumUserHeaderMeta';
import ShippingForm from './ShippingForm';

const { Content } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const APPLE_STORE_URL = 'https://apps.apple.com/us/app/mindcotine/id1506021271?ls=1';
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.habitfly.mindcotine';
const getStoreUrlByName = storeName => {
  return storeName === 'ios' ? APPLE_STORE_URL : GOOGLE_PLAY_URL
}

const registerShippingInfoUser = (uid, values) => {
  return firestore()
    .collection('shippingDataByUID')
    .doc(uid)
    .set({
      name: values.name,
      address: values.address,
      city: values.city,
      zip: values.zip,
      country: values.country,
      contact_phone: values.phone,
    });
};

const GympassRegistrationSuccess = ({
  actions,
  isLoading: busy,
  email: decryptedEmail,
  country: decryptedCountry,
  name: decryptedName,
  lastname: decryptedLastName,
  uid,
}) => {
  const { t } = useTranslation(['gympass_registration']);
  const [form] = Form.useForm();
  const [busyMessage, setBusyMessage] = useState(t('busy_message_saving'));
  const { setEmail, setTheme, openChat } = useCrisp();
  const [storeName, setStore] = useState('android');

  useEffect(() => {
    if (decryptedEmail) {
      setTheme('teal');
      setEmail(decryptedEmail);
    }
  }, [decryptedEmail]);

  const submit = async values => {
    actions.setLoading(1);
    let success = false;
    // wait for user registration, on fail alert error message
    try {
      success = await registerShippingInfoUser(uid, values);
    } catch (error) {
      alert(error.message);
    }
    
    // if user was registered, redirect to next page
    // basic: to app store
    // premium: to shipping
    if (success !== false) {
        setBusyMessage(t('busy_message_store_redirecting'));
        window.location.href = getStoreUrlByName(storeName);
    } else {
      actions.setLoading(-1);
    }
  };
  
  return  (
    <Layout className="gympass-registration-success form-layout">
      <Content>
        <div className="header" />
        <Card bordered={false} className="registration-card"
          cover={<img alt="MindCotine" className="cover-logo" src="/logo216.png" />}
        >
          <PremiumUserMeta />
          {!decryptedEmail && (
            <h2 style={{ color: 'rgb(0, 186, 127)' }}>{t('form_error_required_email')}</h2>
          )}
          {decryptedEmail && (
            <ShippingForm
              form={form}
              initialValues={{
                name: `${decryptedName} ${decryptedLastName}`,
                country: decryptedCountry,
                email: decryptedEmail,
              }}
              onFinish={submit}
              setStore={setStore}
            />
          )}

          <div className="registration-footer">{t('support_label')}&nbsp;<a onClick={openChat}>{t('support_CTA')}</a></div>
        </Card>
      </Content>
      <Modal
        centered
        visible={busy}
        footer={null}
        closable={false}
      >
        <Spin indicator={antIcon} /> {busyMessage}...
      </Modal>
    </Layout>
  )
};

const mapStateToProps = state => ({ ...state.gympass });
const mapDispatchToProp = dispatch => ({ actions: bindActionCreators(actions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProp)(GympassRegistrationSuccess);