import React from 'react';
import { Layout, Menu } from 'antd';
import useAuth from './../../hooks/useAuth';
import { Link } from 'react-router-dom';
import './index.scss';
const { Header } = Layout;

const GenericHeader = () => {
  const { signOut, authed } = useAuth();

  const handleMenuClick = ({ key }) => {
    if (key === 'sign-out') {
      signOut();
    }
  }

  return (
    <Header style={{ width: '100%' }}>
      <Link to="/admin"><div className="header-logo" /></Link>
      <Menu theme="dark" mode="horizontal" onClick={handleMenuClick}>
        {/* <Menu.Item key="1">nav 1</Menu.Item>
        <Menu.Item key="2">nav 2</Menu.Item>
        <Menu.Item key="3">nav 3</Menu.Item> */}
        <Menu.SubMenu key="user" style={{ float: 'right' }} title={authed.displayName}>
          <Menu.Item key="sign-out">Sign out</Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Header>
  );
};

export default GenericHeader;
