import React, { useEffect, useState } from 'react';

const openChat = () => window.$crisp.push(['do', 'chat:open']);
const onClose = () => window.ReactNativeWebView.postMessage("chat:closed");
const onOpen = () => window.ReactNativeWebView.postMessage("chat:opened");

function CoachSupport() {
  const [crispReady, setCrispReady] = useState();
  useEffect(() => {
    if(!window.$crisp) {
      window.$crisp = [];
    }
    window.CRISP_WEBSITE_ID = "162da537-8f73-4d96-9d27-492cabc0ccab";
    (function () {
      const d = window.document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      s.addEventListener.onLoad = setCrispReady(true);
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    const $crisp = window.$crisp;
    if (crispReady) {
      $crisp.push(["on", "chat:closed", onClose]);
      $crisp.push(["on", "chat:opened", onOpen]);
      if (window.injectedEmail) {
        $crisp.push(["set", "user:email", window.injectedEmail]);
      }
      $crisp.push(["config", "show:operator:count", [false]])
      $crisp.push(["config", "color:theme", ["teal"]]);
      openChat();
    }
    return () => {
      $crisp.push(["off", "chat:closed", () => onClose]);
      $crisp.push(["off", "chat:opened", () => onOpen]);
    }
  }, [crispReady])

  return (
    <>
      <span style={{ color: '#fafafa', backgroundColor: 'teal', display: 'flex', width: '100%', height: '100vh' }}>{window.startingText}</span>    
    </>
  );
}

export default CoachSupport;
