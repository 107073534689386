/* global gtag */
import React from 'react';
import {
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import googlePlayLogo_en from './../../assets/submitWithGooglePlay_en.png';
import appleStoreLogo_en from './../../assets/submitWithAppleStore_en.png';
import googlePlayLogo_es from './../../assets/submitWithGooglePlay_es.png';
import appleStoreLogo_es from './../../assets/submitWithAppleStore_es.png';


const getButtonByLAnguage = lang => {
  switch(lang) {
    case 'en':
      default:
      return { apple: appleStoreLogo_en, google: googlePlayLogo_en };
    case 'es':
      return { apple: appleStoreLogo_es, google: googlePlayLogo_es };
  }
}

const OperatingSystem = /android/i.test(navigator.userAgent)
  ? 'android'
  : /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  ? 'ios'
  : 'unknown';
export default ({ form, initialValues, onFinish, setStore }) => {
  const { t, i18n } = useTranslation(['gympass_registration']);
  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout="vertical"
    >
      <div className="registration-footer">{t('shipping_form_subtitle')}</div>
      <br />
      <h2>{t('shipping_form_step1')}</h2>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t('shipping_form_name_label')} name="name"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('form_error_required_name'),
              },
              {
                min: 5,
                message: t('form_error_length_name'),
              }
            ]}
          >
            <Input placeholder={t('shipping_form_name_placeholder')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('shipping_form_address_label')} name="address"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('form_error_required_address'),
              },
              {
                min: 4,
                message: t('form_error_length_address'),
              }
            ]}
          >
            <Input placeholder={t('shipping_form_address_placeholder')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('shipping_form_zip_label')} name="zip"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('form_error_required_zip'),
              }
            ]}
          >
            <Input placeholder={t('shipping_form_zip_placeholder')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('shipping_form_city_label')} name="city"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('form_error_required_city'),
              }
            ]}
          >
            <Input placeholder={t('shipping_form_city_placeholder')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('shipping_form_country_label')} name="country">
            <Input readOnly="readonly" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('shipping_form_phone_label')} name="phone"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('form_error_required_phone'),
              },
              {
                min: 6,
                message: t('form_error_length_phone'),
              }
            ]}
          >
            <Input placeholder={t('shipping_form_phone_placeholder')} />
          </Form.Item>
        </Col>
      </Row>
          <div className="registration-footer">{t('shipping_after_form_instructions')}</div>
      <br/><br/>
      <h2>{t('shipping_form_step2')}
      <br/><br/>
      <code style={{ color: 'rgb(0, 186, 127)' }}>{initialValues.email}</code>
      <br /><br />
      </h2>
    <br />
    <Form.Item>
      <div className="submit-row">
        {(OperatingSystem === 'android' || OperatingSystem === 'unknown') &&
          <a href="#submit_android" className="link-submit-img" onClick={() => {
            setStore('android');
            form.submit();
            gtag('event', 'cta gympass_form_android', {
              'category': 'CTA',
              'action': 'cta clicked',
              'label': 'cta_gympass_android'
            });
          }}>
            <img type="submit" src={getButtonByLAnguage(i18n.language).google} className="logo" alt="Google Play" />
          </a>
        }
        {(OperatingSystem === 'ios' || OperatingSystem === 'unknown') &&
          <a href="#submit_ios" className="link-submit-img" onClick={() => {
            setStore('ios');
            form.submit();
            gtag('event', 'cta gympass_form_ios', {
              'category': 'CTA',
              'action': 'cta clicked',
              'label': 'cta_gympass_ios'
            });
          }}>
            <img type="submit" src={getButtonByLAnguage(i18n.language).apple} className="logo" alt="App Store" />
          </a>
        }
        </div>
      </Form.Item>
    </Form>
  )
}