import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import App from './screens/App';
import Login from './screens/Login';
import SMSAdmin from './screens/SMSAdmin';
import UsersList from './screens/UsersList';
import InspectEmails from './screens/InspectEmails';
import UserOverview from './screens/UserOverview';
import Dashboard from './screens/Dashboard';
import EmbedReport from './screens/EmbedReport';
import GympassRegistration from "./screens/GympassRegistration";
import GympassRegistrationSuccess from "./screens/GympassRegistrationSuccess";
import CoachSupport from './screens/CoachSupport';
import UserChatRedirect from './screens/UserChatRedirect';
// import ADPLanding from './screens/ADPLanding';
// import ADPSuccess from './screens/ADPSuccess';
import useAuth from './hooks/useAuth';
import 'antd/dist/antd.css';
import './index.scss';
import iniFirebase from './config/firebase';
// import UsersOverview from './screens/UsersOverview';
import UserTimeline from './screens/UserTimeline';
import './config/i18n';

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  const {isAdmin, initializing} = useAuth();
  if (initializing) return null;
  return (
    <Route
      {...rest}
      render={props => {
        if (isAdmin) {
          return (
            <Fragment>
              {render ? render(props) : <Component {...props} />}
            </Fragment>
          );
        } else {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

Sentry.init({
  dsn: "https://887d8d8907c74caa80096b0969a1b4cb@o559597.ingest.sentry.io/5694548",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const boot = async () => {
  await iniFirebase();
  ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/admin/sms" component={SMSAdmin} />
          <ProtectedRoute exact path="/admin/crmexport" component={UsersList} />
          <ProtectedRoute exact path="/admin/inspectEmails" component={InspectEmails} />
          <ProtectedRoute exact path="/admin/embed_report" component={EmbedReport} />
          {/* <ProtectedRoute exact path="/admin/users" component={UsersOverview} /> */}
          <ProtectedRoute exact path="/admin/users/:uid/timeline" component={UserTimeline} />
          <ProtectedRoute exact path="/admin/users/:uid" component={UserOverview} />
          <ProtectedRoute exact path="/admin" component={Dashboard} />
          <Route exact path="/support/coach" component={CoachSupport} />
          <Route exact path="/support/redirect/:uid" component={UserChatRedirect} />
          <Route exact path="/integrations/gympasssignup" component={GympassRegistration} />
          <Route exact path="/integrations/gympasssuccess" component={GympassRegistrationSuccess} />
          {/* <Route exact path="/integrations/adp_landing" component={ADPLanding} />
          <Route exact path="/integrations/adp_success" component={ADPSuccess} /> */}
          <Route exact path='/r/education' render={() =>
            window.location.href = 'https://drive.google.com/file/d/1Nlbc4GCaPedOH6QJ-kBnt3FZrNJnb5xI/view'
          }/>
          <Route exact path='/r/screening' render={() =>
            window.location.href = 'https://survey.zohopublic.com/zs/4bBUxt'
          } />
          <Route exact path="/r/codes" render={() => {
            const codes = ['846880', '099430', '683119', '704338', '032959', '890282',
  '384687', '483842', '856667', '513598', '801225', '926976',
  '420951', '704648', '956392', '121910', '658885', '231707',
  '603392', '932188', '466712', '797443', '981831', '065954',
  '989731', '764153', '276975', '154402', '662967', '611163',
  '770497', '828560', '074103', '166893', '933444', '057983',
  '348967', '449894', '646473', '019230', '014037', '387532',
  '330927', '283008', '839401', '682027', '143038', '394727',
  '396504', '911826', '541938', '591227', '856276', '288483',
  '235910', '795121', '245440', '876194', '434158', '984668',
  '148659', '822870', '726410', '612977', '425100', '921295',
  '474991', '717378', '421827', '712956', '692242', '345532',
  '153685', '268106', '832913', '143338', '749818', '602655',
  '007395', '601652', '701700', '294757', '160931', '794799',
  '306340', '821850', '280855', '987272', '957755', '174531',
  '824607', '526912', '602828', '334363', '521817', '917723',
  '212430', '923684', '272461', '718956'];
            return <div className="tags-container">{codes.map(code => (
              <div className="tag">
                <div className="label">Código de activación</div>
                <div className="code">{code}</div>
              </div>
            ))}
            </div>
          }} />
          <Route exact path="*" component={App} />
        </Switch>
      </Router>
    </Provider>
    // </React.StrictMode>
    ,
    document.getElementById('MindCotineRoot')
  );
}
boot();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
