import { useState, useEffect } from 'react';
import { firestore } from 'firebase';

const useFirestoreSMS = (uid) => {
  const [history, setHistory] = useState([]);
  useEffect(() => {
    if (uid) {
      const unsubscribe = firestore()
        .collection('users')
        .doc(uid)
        .collection('sms')
        .orderBy('created_at')
        .onSnapshot(smsSnapshot => {
          const data = [];
          smsSnapshot.forEach(d => {
            data.push(d.data());
          })
          setHistory(data.reverse());
        });
      return () => unsubscribe();
    }
  }, [uid]);
  return [history];
}

export default useFirestoreSMS;
