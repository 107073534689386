import React, { useState } from 'react';
import * as firebase from 'firebase';

export default () => {
  const [voteResultIsVisible, showVoteResult] = useState(false);
  const browserLang = (navigator.language || navigator.userLanguage).substr(0,2);
  function upvoteTranslation(lang) {
    showVoteResult(true);
    firebase.analytics().logEvent(`landing_upvote_${lang}`);
  }
  return (
    <div style={{ position: 'relative' }}>
      {browserLang === 'pt' && (
        <>
          <div className="disclaimer-footer">
            * MindCotine só está disponível em Inglês no momento. Gostaria de ter o MindCotine no seu idioma? <a onClick={() => upvoteTranslation(browserLang)} >Upvote português aqui!</a>
          </div>
          <div className={`vote-result-panel opacity ${voteResultIsVisible ? 'show' : 'hide'}`} style={{ position: 'absolute', top: 0, width: '100%', backgroundColor: 'white' }}>
            Obrigado por isso, vamos considerar.
          </div>
        </>
      )}
      {browserLang !== 'es' && browserLang !== 'pt' && browserLang !== 'en' && (
        <>
          <div className="disclaimer-footer">
            * MindCotine is only available in English at the moment. Would you like to have MindCotine in your language? <a onClick={() => upvoteTranslation(browserLang)} >Upvote it here</a>
          </div>
          <div className={`vote-result-panel opacity ${voteResultIsVisible ? 'show' : 'hide'}`} style={{ position: 'absolute', top: 0, width: '100%', backgroundColor: 'white' }}>
            Thanks for voting, We'll consider it.
          </div>
        </>
      )}
    </div>
)
    }