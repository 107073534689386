import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import "./BasicUserHeaderMeta.scss";
import kitImage from "../../assets/kit_perspective.png";

const { Meta } = Card;

export default () => {
  const { t } = useTranslation(['gympass_registration']);
  return (
    <Meta
      className="registration-card-meta"
      title=""
      description={
      <div style={{ display: 'flex', backgroundColor: '#fafafa', alignItems: 'center', padding: 10, marginTop: 20, borderRadius: 5 }}>
        <img src={kitImage} style={{ height: 100, margin: '0 10px' }} />
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, padding: 10 }}>
        <p style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: 'normal', color: '#00ba7f' }}>{t('premium_registration_banner_text')} <span style={{ color: '#2a5f66' }}>{t('premium_registration_banner_text_highlight')}</span></p>
        </div>
      </div>}
    />
  )
}
