export const APPLE_STORE_URL = 'https://apps.apple.com/us/app/mindcotine/id1506021271?ls=1';
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.habitfly.mindcotine';
export const SHIPPABLE_COUNTRIES = [
  'ar',
  'us',
  // 'ca',
  'mx',
  // 'uk',
  'cl',
  // 'br',
];