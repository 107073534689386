import React from 'react';
import logo_bg from './logo_bg.png';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-container">
          <img src={logo_bg} className="App-logo-bg" alt="logo" />
          <img src={logo} className="App-logo" alt="logo" />
        </div>

        <p style={{ position: 'absolute', right: 0, bottom: 0, margin: 0 }}>
          🚭
        </p>
        <div className="home-links">
          <a
            className="App-link"
            href="https://www.mindcotine.com"
            target="_self"
            rel="noopener noreferrer"
          >
            WEB SITE
          </a>
          <a
            className="App-link"
            href="/admin"
            target="_self"
            rel="noopener noreferrer"
          >
            Dashboard
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
