import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation(['gympass_registration']);
  return (
    <>
      <br/>
      <small>{t('legal_tc_1')}<a href="https://mindcotine.com/terms-of-use" target="_blank">{t('legal_tc_2')}</a></small>
    </>
  );
}