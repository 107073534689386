import React, { useState, useEffect } from 'react';
import { Layout, Button, Menu, Breadcrumb, Card, Col, Row } from 'antd';
const { Header, Content, Footer } = Layout;

const getReportByParam = param => {
  const usersOverview = 'https://datastudio.google.com/embed/reporting/1jERZbjnAM9j3zBKHm_XjloVlT-_qUen0/page/XUrPB';
  const usersAroundQD = 'https://datastudio.google.com/embed/reporting/16euNEXB2XdjFPCuXUnvsdsJaoGqCslMK/page/XILSB';
  return usersOverview;
}
const EmbedReport = () => {
  const reportUrl = getReportByParam();
  return (
    <Layout className="layout" style={{ height: '100vh' }}>
      <Header>Header</Header>
      <Content style={{ padding: '0', margin: '0' }}>
        <iframe style={{ minWidth: '100%', minHeight: '100%' }} src={reportUrl}></iframe>
      </Content>
    </Layout>
  )
}

export default EmbedReport;