import React, { useState, useEffect } from 'react';
import { functions, auth } from 'firebase';
import { Table, Button, Layout, PageHeader } from 'antd';
import { CSVLink } from 'react-csv';
import moment from 'moment';
// import useAuth from './../../hooks/useAuth';
import GenericFooter from './../../components/GenericFooter';
import GenericHeader from './../../components/GenericHeader';

const { Content } = Layout;

const useAuthUsers = () => {
  const [users, setUsers] = useState();
    useEffect(() => {
      const loadUser = async () => {
        const usersResult = await functions().httpsCallable('dengerouslyGetAuthUsers')();
        setUsers(usersResult.data);
      }
      // alert(auth().currentUser.email);
      if (['emilio@mindcotine.com', 'marcos@mindcotine.com'].includes(auth().currentUser.email)) {
        console.log('LOAD');
        loadUser();
      } else {
        setUsers([]);
      }
    }, []);
    return users;
};

const useTable = users => {
  const [ds, setDS] = useState();
  const [cols, setCols] = useState();
  useEffect(() => {
    if(users) {
      const dataSource = users
        .sort((a, b) => moment(b.metadata.creationTime) - moment(a.metadata.creationTime))
        .map(u => (
          {
            key: u.uid,
            firstname: u.displayName?.split(' ')[0] || '',
            lastname: u.displayName?.split(' ')[1] || '',
            email: u.email,
            phone: u.phoneNumber,
            createdAt: u.metadata.creationTime,
            lastSignInAt: u.metadata.lastSignInTime,
            profile: u.profile || u.group,
          })
        );
      const columns = [
        {
          title: 'UID',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'First Name',
          dataIndex: 'firstname',
          key: 'firstname',
        },
        {
          title: 'Last Name',
          dataIndex: 'lastname',
          key: 'address',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Profile',
          dataIndex: 'profile',
          key: 'profile',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
          defaultSortOrder: 'descend',
          sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Last Sign In At',
          dataIndex: 'lastSignInAt',
          key: 'lastSignInAt',
          sorter: (a, b) => moment(a.lastSignInAt) - moment(b.lastSignInAt),
          sortDirections: ['ascend', 'descend'],
        }
      ];
      setDS(dataSource);
      setCols(columns);
    }
  }, [users])

  return [ds, cols];
}
const UsersList = () => {
  const [loading, setLoading] = useState(true);
  const users = useAuthUsers();

  const [dataSource, columns] = useTable(users);
  // const [dataSource, columns] = [];
  useEffect(() => {
    if (users) {
      setLoading(false);
    }
  }, [users]);
  
  return (
    <Layout className="layout">
      <GenericHeader />
      <Content style={{ padding: '0 25px', margin: '25px' }}>
        <PageHeader
          title="Registered Users"
          extra={[
            dataSource && (
              <Button
                key="cvs_download_link"
              >
                <CSVLink
                  filename={`MindCotine_users_export_${moment().format('YYYY-MM-DD_HH:mm:ss')}.csv`}
                  data={dataSource}
                  enclosingCharacter={'"'}
                >Download!</CSVLink>
              </Button>
            )
          ]}
        />
        <div className="site-layout-content">
          <Table
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            size="small"
            locale={{
              emptyText: 'Disabled by security reasons'
            }}
            pagination={{ pageSize: 20, showTotal: t => `${t} users` }}
          />
        </div>
      </Content>
      <GenericFooter />
    </Layout>
  );
}

export default UsersList;