import React from "react";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

export default () => {
  const { uid } = useParams();
  useEffect(() => {
    window.location.href = `https://datastudio.google.com/reporting/1jERZbjnAM9j3zBKHm_XjloVlT-_qUen0/page/H7xPB?params=%7B%22df8%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${uid}%22%7D`;
  }, []);
  return null;
}