export const setLoading = state => ({
  type: 'SET_LOADING',
  payload: state,
});

export const setBusyMessage = message => ({
  type: 'SET_BUSY_MESSAGE',
  payload: message,
});

export const setParams = params => ({
  type: 'SET_PARAMS',
  payload: params,
});

export const setUid = uid => ({
  type: 'SET_UID',
  payload: uid,
});

export const setIsPremium = state => ({
  type: 'SET_IS_PREMIUM',
  payload: state,
});

export const setHasShippingInfo = state => ({
  type: 'SET_HAS_SHIPPING_INFO',
  payload: state,
});
export const setAlreadyRegistered = state => ({
  type: 'SET_ALREADY_REGISTERED',
  payload:state,
})
