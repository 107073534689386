import React, { useState, useEffect } from 'react';
import { Input, Layout, Button, Menu, Breadcrumb } from 'antd';
import axios from 'axios';
import moment from 'moment';
import GenericFooter from './../../components/GenericFooter';
import useFirestoreUser from './../../hooks/useFirestoreUser';
import useFirestoreSMS from './../../hooks/useFirestoreSMS';
import GenericHeader from './../../components/GenericHeader';
import env from './../../config/env';

const apiEndpoint = env.domain + env.apiPath;
const { Header, Content } = Layout;
const { TextArea } = Input;

const SMSAdmin = () => {
  const [uid, setUID] = useState();
  const [composeBody, setBody] = useState('');
  const [error, setError] = useState();
  const [history] = useFirestoreSMS(uid);
  const [user, userError] = useFirestoreUser(uid);
  const handleUID = uid => {
    setError('');
    setUID(uid);
  }
  const handleBody = evt => {
    setError('');
    if (evt.currentTarget.value.trim() !== composeBody.trim()) {
      setBody(evt.currentTarget.value);
    }
  }
  const sendMessage = () => {
    if (composeBody.trim().length !== 0) {
      axios.post(`${apiEndpoint}/sms/send`, {
        uid,
        message: composeBody,
      })
        .then(function (response) {
          console.log(response.data);
          setBody('');
        })
        .catch(function (error) {
          if (error.response) {
            setError(error.response.data.message);
          }
        });
    }
  }

  return (
    <Layout className="layout">
      <GenericHeader />
      <Content style={{ padding: '0 50px', margin: '50px' }}>
        <div className="site-layout-content">
          <Input placeholder="enter UID" onChange={evt =>
            handleUID(evt.currentTarget.value)
          } value={uid} />
          <TextArea rows={4} onChange={handleBody}>{composeBody}</TextArea>
          <Button onClick={sendMessage}>Send</Button>
        </div>
        {error && <span style={{color: 'red' }}>ERROR: {error}</span>}
        {userError && <span style={{ color: 'red' }}>ERROR: {userError}</span>}
        <br/>
        {user?.display_name}
        <h2>History</h2>
        {history.map((h, idx) => (
          <div key={`history_${idx}`}>
            {moment.unix(h.created_at?.seconds || 0).format('YYYY-MM-DD HH:mm:ss')}
            <br/>
            <span style={{ fontSize: 40 }}>{h.Direction === 'outbound-api' ? '📤' : '📩'}</span>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{h.Body}</pre>
          </div>
        ))}
      </Content>
      <GenericFooter />
    </Layout>
  );
}

export default SMSAdmin;