import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export default ({ email, hasShippingData, navigateToShippingForm }) => {
  const { t } = useTranslation(['gympass_registration']);
  return (
    <React.Fragment>
      <div className="registration-footer">{t('already_registered_text')}</div>
      {!hasShippingData && (
        <React.Fragment>
          <div className="registration-footer"><b>{t('already_registered_text_gift')}</b></div>
          <br /><br />
          <Button type="primary" htmlType="submit" className="MindCotine_btn" onClick={navigateToShippingForm}>
            {t('already_registered_gift_CTA')}
          </Button>
        </React.Fragment>
      )}
      {hasShippingData && (
        <React.Fragment>
          <h2>{t('premium_registered_instruction')}</h2>
          <br /><br />
          <h2><code>{email}</code></h2>
          <br /><br />
          <div className="registration-footer">{t('premium_registered_footer')}</div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
};
