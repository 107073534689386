import * as firebase from 'firebase';
import env from './env';

// PROD/LIVE CONFIG
const fetchConfig = () => fetch('/__/firebase/init.json').then(async response => await response.json());

// DEVEL/LOCAL CONFIG
const getConfig = async () => {
  var config = {
    apiKey: "AIzaSyDNt8jwB1aq2_o6O9fv6sj0hkT3-e9-2Bg",
    authDomain: "mindcotine-v4-dev.firebaseapp.com",
    databaseURL: "https://mindcotine-v4-dev.firebaseio.com",
    projectId: "mindcotine-v4-dev",
    storageBucket: "mindcotine-v4-dev.appspot.com",
    messagingSenderId: "353015682620",
    appId: "1:353015682620:web:edf5bdb4e8446088101539",
    measurementId: "G-Z9RKTTYY01"
  };
  return Promise.resolve(config);
}

const iniFirebase = async () => {
  const config = env.type === 'development'
    ? await getConfig()
    : await fetchConfig();
  // Initialize Firebase
  firebase.initializeApp(config);
  firebase.analytics();
  return true;
}

export default iniFirebase;