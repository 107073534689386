import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import "./BasicUserHeaderMeta.scss";
import kitImage from "../../assets/anim03-low.png";

const { Meta } = Card;

export default () => {
  const { t } = useTranslation(['gympass_registration']);
  return (
    <Meta
      className="registration-card-meta"
      title=""
      description={
      <div style={{
        display: 'flex',
        backgroundColor: '#fafafa',
        padding: 10,
        marginTop: 20,
        borderRadius: 5,
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      }}>
        <img
          src={kitImage}
          style={{
            height: 200,
            transform: 'scaleX(-1)',
            margin: '0 0 -10px -10px',
          }}
        />
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          padding: 10,
          marginBottom: -60,
        }}>
          <p style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: 'normal', color: '#00ba7f' }}>
            {t('premium_shipping_banner_text')} <span style={{ color: '#2a5f66' }}>{t('premium_shipping_banner_text_highlight')}</span></p>
        </div>
      </div>}
    />
  )
}
