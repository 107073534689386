import React, { useState } from 'react';
import { Input, Layout, Button, Card } from 'antd';
import useAuth from './../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import GenericFooter from './../../components/GenericFooter';
import SignInGoogleButton from '../../components/SignInGoogleButton';
import './index.scss';
const { Content } = Layout;

const Login = () => {
  const [user, setUser] = useState();
  const [pass, setPass] = useState();
  const { signInWithEmailAndPassword, signInWithGoogle, error, authed, isAdmin} = useAuth();
  const handleUserName = evt => setUser(evt.currentTarget.value);
  const handlePassword = evt => setPass(evt.currentTarget.value);
  const submit = async () => signInWithEmailAndPassword(user, pass);
  return (
    <React.Fragment>
      {authed && isAdmin && <Redirect to="/admin" />}
      <Layout className="form-layout">
        <Content>
          <div className="header" />
          <Card bordered={false} className="registration-card login-card"
            cover={<img alt="MindCotine" className="cover-logo" src="/logo216.png" />}
          >
            <SignInGoogleButton onClick={signInWithGoogle}>Sign In with Google</SignInGoogleButton>
            <br/>
            <div className="site-layout-content">
              <Input placeholder="username" onChange={handleUserName} value={user} />
              <Input placeholder="password" onChange={handlePassword} value={pass} type="password" />
                <Button onClick={submit}>Submit</Button>
              <br/>
              {error && <span style={{color: 'red' }}>{error}</span>}
            </div>
          </Card>
        </Content>
        <GenericFooter />
      </Layout>
    </React.Fragment>
  );
};

export default Login;
