import { useState, useEffect } from 'react';
import { functions } from 'firebase';

const useUserLogs = uid => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setError('');
    if (uid) {
      const loadUser = async () => {
        try {
          const response = await functions().httpsCallable('getUserLogs')(uid);
          setUser(response.data);
        } catch (err) {
          setError(err.message);
        }
        setLoading(false)
      }
      loadUser();
    }
  }, [uid]);
  return [user, error, isLoading];
}

export default useUserLogs;
